import React from 'react'
import { Logo, IconBar } from "../";

import "./Footer.scss";

export const Footer = () => {
  return (
  <>
    <footer>
      <Logo /><IconBar />
    </footer>
  </>
  )
}

