import React from "react";

import  "./ContentHeader.scss";

export const ContentHeader = ({titleCont}) => {

    return (
        <div className="content-header">
        <h2>{titleCont}</h2>
        <div className="line" />
        </div>

    )
}