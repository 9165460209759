import React from "react";

import "./Aboutme.scss"

import {ContentHeader} from "../ContentHeader";
export const Aboutme = () => {
    return (
        <section id="4" className="content-wrapper">
            <div className="content-block aboutme">
            <ContentHeader titleCont="Об адвокате" />
            <div className="content-block-item">
                <div className="row">
                <div className="col item1">
                    <div className="img_container background">
                        {/* <img src="/img/img_prof.png" alt=""/> */}
                    </div>
                    </div>
                    <div className="col item2">
                    
                        <h3>Салаватулина Анна Юрьевна</h3>
                        <p>
                            Я являюсь квалифицированным специалистом в области юриспруденции со стажем более 15 лет, с 2020 года веду деятельность в статусе адвоката Западно-Сибирской коллегии адвокатов.
                        </p>
                        <p>
                            Географический охват моей деятельности включает следующие территории: Тюменская область, ХМАО, ЯНАО, Свердловская область, Челябинская область, г. Москва, г. Санкт-Петербург.
                        </p>
                        <p>
                            То, что для Вас неразрешимая проблема – для меня повседневная ситуация, работа над которой позволяет добиться положительных результатов. Готова обеспечить для Вас доступность, оперативность, конфиденциальность. Нацеленность на результат - ключевое, что имеет значение, поэтому Вы всегда будете знать о ходе Вашего дела и дальнейших мероприятиях, необходимых для достижения ожидаемого результата. Если желаемый результат невозможен, труднодостижим или непредсказуем, Вы заранее будете знать об этом. Своевременная помощь адвоката очень важна для положительного результата, поскольку она увеличивает шансы решения вопроса в вашу пользу и позволяет сэкономить Ваше время и средства.
                        </p>
                        </div>
                </div>
            </div>
            </div>
        </section>
    )
}