import React from 'react'

import "./MainScreen.scss"

export const MainScreen = () => {
    return (
      <section id="1" className="content-wrapper">
        <div className="mainscreen background">
          <div className="img_container">
            <img src="/img/mainscreen-background.jpg" alt=""/>
          </div>
            <div className="mainscreen-item">
              <div className='mainscreen-item__header'>
                <h1>Анна Салаватулина</h1>
                <h2>Адвокат</h2>
              </div>
            <p>Оказываю помощь в решении вопросов правового характера гражданам и организациям.</p> 
          </div>
        </div>
      </section>
    )
  }