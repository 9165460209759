import React from 'react'
import "./Header.scss"; 

import { Logo, IconBar, Nav } from "../";

export const Header = () => {
  return (
   <header>
     <div className="wrapper">
       <Nav />
     </div>
     <div className="wrapper"><Logo /></div>
     <div className="wrapper"><IconBar /></div>
     
       
       
    
  </header>
  )
}
