import React from 'react'
import {Routes, Route } from 'react-router-dom'

import {HomePage} from './pages/homePage'

export const useRouts = () => {
    return (
        <>
            <Routes>
                <Route path = "/HomePage" element = {<HomePage />} exact>
                </Route>
                <Route path = "/" element = {<HomePage />} exact />         
            </Routes>
            
            </>
       
    )
}