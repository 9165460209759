import React from 'react'
import classNames from "classnames";

// import map from "../../img/map.png";
import "./ContactBlock.scss"


export const ContactBlock = (className) => {

    return (
      <section id="5" className={classNames("contact-block", className)}>
        <div className="contact-block-item">
          {/* <img src={map} alt={"MAP"}></img> */}
          <h3>Адрес:</h3>
          <p>г.Тюмень, Интернациональная 199 корп. 1</p>
          <p>г.Тюмень, Уральская 53 корп. 1</p>
        </div>
        <div className="contact-block-item">
          <h3>Телефон:</h3>
          <p>+7 922 046 06 70</p>
        </div>
        <div className="contact-block-item">
          <h3>E-mail:</h3>
          <p>salavatulina.a@yandex.ru</p>
        </div>
        
      </section>
    )
  }