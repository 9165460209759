 import React from 'react';

// import {BrowserRouter as Router} from 'react-router-dom';
import {useRouts} from './routes';
import { Header, Footer } from './components';


function App() {
  const routs = useRouts ();
  return (
    <>    
      <div className="App">
        
        <Header />
        
        <div className="Wrapper">{routs}</div>
        <Footer />   
      </div>  
    </>

  );
}

export default App;
