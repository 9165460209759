import React from 'react';

import { MainScreen } from "../components/MainScreen";
import { Specialization } from "../components/Specialization";
import { Proservices } from "../components/Proservices";
import { Aboutme } from "../components/Aboutme";
import { ContactBlock } from "../components/ContactBlock";


export const HomePage = () => {
    
    
    return (
        <>
            <MainScreen /> 
            <Specialization />  
            <Proservices />
            <Aboutme />          
            <ContactBlock />
        </>
        )
}
