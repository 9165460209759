import React, { useState } from 'react';

import {ContentHeader} from "../ContentHeader";
import {ContentCard} from "../ContentCard";

import "./Specialization.scss";



export const Specialization = () => {
    const [specsdata] = useState([
            {
                "id": 1,
                "name": "Арбитраж и услуги для бизнеса",
                "description": [
                    "Корпоративные конфликты",
                    "Взыскание задолженности, неустойки, убытков",
                    "Споры о признании сделок недействительными",
                    "Споры по договорным обязательствам"
                ]
            },
            {
                "id": 2,
                "name": "Гражданские дела",
                "description": [
                    "Споры по ДТП",
                    "Страховые споры: ОСАГО, КАСКО и прочие",
                    "Трудовое право",
                    "Семейные споры",                    
                    "Жилищные споры",
                    "Потребительские споры"
                ]
            },
            {
                "id": 3,
                "name": "Сопровождение исполнительного производства",
                "description": [
                    "Обжалование действий, бездействий судебных приставов",
                    "Подача заявлений и ходатайств",
                    "Участие в исполнительных действиях"
                
                ]
            },
           
            {
                "id": 5,
                "name": "Уголовные дела",
                "description": [
                    "Экономические преступления",
                    "Должностные преступления",
                    "Преступления связанные с мошенничеством присвоением и растратой"
                ]
            }
    ]);
    const [idactive, setIdactive] = useState (0);
    const handleClickCard = (iditem) => {
        setIdactive(iditem)
    };

    return (
        <section id="2" className="content-wrapper">
            <div className="content-block">
            <ContentHeader titleCont="Специализация" />

            <div className="content-block-item specialization">

                <ul className="b-grid">
                    {
                        specsdata.map((item) => {
                            return (
                                
                                <li
                                key={item.id}
                                >
                                    <ContentCard 
                                    
                                    className={"content-card "+(idactive === item.id ? "active" : "")}

                                    title={item.name}
                                    // srcImg={item.img_path}
                                    onClickTitle={e => handleClickCard(item.id)}
                                    >
                                        {
                                        (item.description.length > 1 ? 
                                             <ul>
                                                {
                                                    item.description.map((arritem, index) => {
                                                    return(
                                                        <li
                                                        key={index}
                                                        >
                                                            <p>{arritem}</p>
                                                        </li>
                                                    )
                                                    })
                                                }   
                                            </ul> 
                                        : <p>{item.description}</p>)
                                        }    
                                        
                                        
                                    </ContentCard>
                                </li>
                         )
                        })}
                </ul>
            </div></div>
        </section>
    )
}