import React from 'react'
import classNames from "classnames";

import "./IconBar.scss"

 export const IconBar = ({className}) => {
  return (
    <div className={classNames("IconBar", className)}>
      <ul>
        <li><a className="IconViber" href="viber://chat?number=%2B79220460670"   title="Написать в Viber"><img src="/img/Viber.svg" alt="Написать в Viber" /></a></li>
        <li><a className="IconWhatsapp" href="https://wa.me/+79220460670" title="Написать в WhatsApp"><img src="/img/WhatsApp.svg" alt="Написать в WhatsApp" /></a></li>
        <li><a className="IconMail" href="mailto:salavatulina.a@yandex.ru" title="Написать на E-mail"><img src="/img/Mail.svg" alt="Написать в на E-mail" /></a></li>
      </ul>
    </div>
  )
}
