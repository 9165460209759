import React, { useState } from 'react'
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import "./Nav.scss"; 

 export const Nav = ({className}) => {

  const [menuactive, setMenuactive] = useState (false);
  const handleClickMenuIcon = () => {
    setMenuactive(!menuactive)
  }


  return (
    <>
      <nav className={classNames("Nav"+(menuactive ? " active": ""), className)}>
       <div className={"menu-icon"} onClick={handleClickMenuIcon}>
            <span></span>
        </div>
        
          <ul className="menu-list" onClick={handleClickMenuIcon}>
          <li><NavLink to="/HomePage" >Главная</NavLink></li>
          <li><a href="#2">Специализация</a></li>
          <li><a href="#3">Услуги</a></li>
          <li><a href="#5">Контакты</a></li>
        </ul>
      </nav>
    </>
  
  )
}
